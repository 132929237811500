<template>
  <base-layout>
    <ion-content id="subpage-detail" class="has-header has-subheader has-bottom-banner">
      <div class="map">
        <div v-if="mapItem">
          <l-map ref="mapRef" :minZoom="minZoom" :crs="crs" id="map" style="height: 850px"
                 :center="center" :zoom="zoom"
                 @click="showNearestMarker($event)">
            <l-image-overlay v-if="mapItem.map_file" :url="mapItem.map_file" :bounds="bounds"></l-image-overlay>
            <l-marker ref="markerRef" v-if="currentMarker && currentMarker.coordinate_x"
                      :lat-lng="{lat:currentMarker.coordinate_x, lng:currentMarker.coordinate_y}"
            >
              <l-icon :icon-url="'/assets/icon/pin.png'" :icon-size="iconSize" :icon-anchor="iconAnchor"
                      :popup-anchor="popupAnchor" :shadow-size="shadowSize"/>
              <l-popup ref="popup">
                <router-link :to="currentMarker.link" v-html="currentMarker.name" v-if="currentMarker.link.indexOf('exhibitors') == -1"
                             style="text-decoration:none"></router-link>
                <div v-if="currentMarker.link.indexOf('exhibitors') != -1" v-html="currentMarker.name"
                             style="text-decoration:none"></div>
              </l-popup>
            </l-marker>
          </l-map>
        </div>
        <div v-if="!mapItem">Room currently not linked</div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>

import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import "leaflet/dist/leaflet.css"
import {LImageOverlay, LMap, LMarker, LPopup, LIcon} from "@vue-leaflet/vue-leaflet";
import {CRS} from "leaflet/dist/leaflet-src.esm";

export default defineComponent({
  name: "SubpageDetail",
  data() {
    return {
      bounds: [[0, 0], [2600, 2600]],
      center: [1300, 1300],
      zoom: -2,
      minZoom: -2,
      crs: CRS.Simple,
      iconWidth: 32,
      iconHeight: 39,
      iconAnchor: [5, 32],
      popupAnchor: [2, -30],
      shadowSize: [41, 41],
      mapItem: {},
      map: null,
      markers: [],
      currentMarker: {},
    };
  },


  components: {
    IonContent,
    LMap,
    LImageOverlay,
    LMarker,
    LPopup,
    LIcon
  },
  computed: {
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
  },

  methods: {
    ...mapActions("maps", [
      "getMap", "getAllMarkers", "getMarker", "prepareMarker", "getMapFromLocation", "getMarkerForLocation"
    ]),
    ...mapActions('googleanalytics', ['trackWithLabel']),


    async showNearestMarker(event) {
      if (event.latlng) {
        let lat = event.latlng.lat;
        let lng = event.latlng.lng;
        let nearest = null;
        let i = 0;
        let k = 0;

        this.markers.forEach(function (marker) {
          let distance = ((marker.coordinate_x - lat) * (marker.coordinate_x - lat)) + ((marker.coordinate_y - lng) * (marker.coordinate_y - lng));
          if (nearest === null || distance < nearest) {
            nearest = distance;
            k = i;
          }
          i++;
        });
        this.currentMarker = await this.prepareMarker(this.markers[k])
        setTimeout(() => {
          this.$refs.markerRef.leafletObject.openPopup();
        }, 200);

      }

    },

    iconReady() {
      this.$nextTick(function () {
        console.log("icon ready")
        console.log(this.$refs.iconRef)
        this.$refs.iconRef.focus()
      })
    },


    recenterMap() {
      this.bounds = [[0, 0], [2600, 2600]];
      this.center = [1300, 1300];
      this.zoom = -2;
      this.minZoom = -2;
    }
  },

  async created() {
    this.mapItem = null;


  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Map Detail') {
          this.mapItem = await this.getMap(this.$route.params.id);
          this.markers = await this.getAllMarkers(this.$route.params.id);
        } else if (route.name == 'Map Detail with marker') {
          this.mapItem = await this.getMapFromLocation(this.$route.params.location);
          this.markers = await this.getAllMarkers(this.mapItem.id);
          let marker = await this.getMarkerForLocation(this.$route.params.location);
          this.currentMarker = await this.prepareMarker(marker)
          setTimeout(() => {
            this.$refs.markerRef.leafletObject.openPopup();
          }, 200);
        }

        this.trackWithLabel(this.mapItem.title)
      }
    }
  }
});
</script>

<style scoped>
#subpage-detail {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;
}

#map {
  height: 100%;
}

</style>
